import dayjs from "dayjs";

import { formatIP } from "utils/functions";
import { PROSPECT_TYPE_MAP_PRO, REPORT_TYPE_MAP } from "_react/evals/_constants";

export function getProspectType(ofp) {
	if (ofp >= 70) return "EXC";
	else if (ofp >= 60) return "GOOD";
	else if (ofp >= 55) return "SAVG";
	else if (ofp >= 50) return "AVG";
	else if (ofp >= 45) return "MGN";
	else if (ofp >= 40) return "FRG";
	else if (ofp >= 35) return "ORG";
	else if (ofp >= 30) return "SUM";
	else if (ofp >= 25) return "INJ";
	else if (ofp >= 20) return "DNS";
	return "NP";
}

export function getProspectTypePro(ofp, evaluation) {
	const legacy = evaluation.seen_date != null && dayjs(evaluation.seen_date).year() < 2019 ? true : false;
	if (ofp >= 70) return "EXC";
	else if (ofp >= 60) return "GOOD";
	else if (ofp >= 55) return "SAVG";
	else if (ofp >= 50) return "AVG";
	else if (ofp >= 45) return "MGN";
	else if (ofp >= 43) return "HFRG";
	else if (ofp >= 40) return "LFRG";
	else if (ofp >= 35) return "ORG";
	else if (ofp >= 30) return legacy ? "LIM" : "DNS";
	else if (ofp >= 25) return legacy ? "DNS" : "LIM";
	else if (ofp >= 20) return "INJ";
	return "NP";
}

export function generatePublishDate() {
	const now = new Date();
	const year = "" + now.getFullYear();
	let month = "" + (now.getMonth() + 1);
	if (month.length === 1) {
		month = "0" + month;
	}
	let day = "" + now.getDate();
	if (day.length === 1) {
		day = "0" + day;
	}
	let hour = "" + now.getHours();
	if (hour.length === 1) {
		hour = "0" + hour;
	}
	let minute = "" + now.getMinutes();
	if (minute.length === 1) {
		minute = "0" + minute;
	}
	let second = "" + now.getSeconds();
	if (second.length === 1) {
		second = "0" + second;
	}
	return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
}

export const checkSeenDate = seenDate => {
	if (seenDate == null) return false;
	const seenDatedayjs = dayjs(seenDate);
	const nowdayjs = dayjs();
	return seenDatedayjs.year() === nowdayjs.year();
};

export const addInnings = (base, add) => {
	let baseConverted = parseFloat(Math.abs(base), 10.0) || 0.0;
	baseConverted = (baseConverted % 1) * 10 * (1.0 / 3.0) + Math.floor(baseConverted);
	baseConverted = baseConverted * ((parseFloat(base, 10.0) || 0.0) > 0 ? 1 : -1);

	let addConverted = parseFloat(Math.abs(add), 10.0) || 0.0;
	addConverted = (addConverted % 1) * 10 * (1.0 / 3.0) + Math.floor(addConverted);
	addConverted = addConverted * ((parseFloat(add, 10.0) || 0.0) > 0 ? 1 : -1);

	return formatIP(baseConverted + addConverted);
};

export function isNumeric(value) {
	return Number(parseFloat(value)) == value; // eslint-disable-line eqeqeq
}

export function correctIP(ip) {
	if (ip == null || ip === "") return ip;
	if (`${ip}`.split(".").length < 2) return ip;
	if (`${ip}`.split(".")[1] === "") return ip;
	else if (parseInt(`${ip}`.split(".")[1].substring(0, 1), 10) > 5) return `${`${ip}`.split(".")[0]}.2`;
	else if (parseInt(`${ip}`.split(".")[1].substring(0, 1), 10) > 2.5) return `${`${ip}`.split(".")[0]}.1`;
	return Math.round(ip * 10.0) / 10.0;
}

export function getSelectValue(evaluation, field, options) {
	if (!evaluation.hasOwnProperty(field) || evaluation[field] == null) return "";
	const option = options.filter(e => e.value === evaluation[field]);
	if (option.length !== 1) return evaluation[field];
	return option[0].text;
}

export const prepareEvalPro = (evaluation, previousEval) => {
	if (evaluation == null) return evaluation;

	// Check for injured report
	if (previousEval && previousEval.ofp === 20) {
		return {
			...evaluation,
			record_status: "PR",
			eval_report_type: "PRO",
			summary: previousEval.summary,
			current_snapshot: previousEval.current_snapshot,
			injury_notes: previousEval.injury_notes
		};
	}

	const nowYear = dayjs().year();
	const reportYear = previousEval.seen_date != null ? dayjs(previousEval.seen_date).year() : dayjs().year();
	// Check if report is previous year, or it's currently July or later and report was June or earlier
	if (nowYear - 1 === reportYear) {
		// Only copy over
		let fieldsToKeep = [];
		if (evaluation.eval_type.toLowerCase() === "pro_pitch") {
			fieldsToKeep = [
				"cb_cmd_comments",
				"cb_cmd_future",
				"cb_cmd_present",
				"cb_comments",
				"cb_future",
				"cb_present",
				"cb_vel_high",
				"cb_vel_low",
				"ch_cmd_comments",
				"ch_cmd_future",
				"ch_cmd_present",
				"ch_comments",
				"ch_future",
				"ch_present",
				"ch_vel_high",
				"ch_vel_low",
				"fb_cmd_comments",
				"fb_cmd_future",
				"fb_cmd_present",
				"fb_mov_comments",
				"fb_mov_future",
				"fb_mov_present",
				"fb_vel_comfort_zone_high",
				"fb_vel_comfort_zone_low",
				"fb_vel_comments",
				"fb_vel_future",
				"fb_vel_high",
				"fb_vel_low",
				"fb_vel_present",
				"fb_vel_grade_present",
				"fb_vel_grade_future",
				"fb_quality_present",
				"fb_quality_future",
				"oth_cmd_comments",
				"oth_cmd_future",
				"oth_cmd_present",
				"oth_pitch_comments",
				"oth_pitch_future",
				"oth_pitch_present",
				"oth_pitch_vel_high",
				"oth_pitch_vel_low",
				"oth2_cmd_comments",
				"oth2_cmd_present",
				"oth2_cmd_future",
				"oth2_pitch_future",
				"oth2_pitch_present",
				"oth2_pitch_vel_high",
				"oth2_pitch_vel_low",
				"sl_cmd_comments",
				"sl_cmd_future",
				"sl_cmd_present",
				"sl_comments",
				"sl_future",
				"sl_present",
				"sl_vel_high",
				"sl_vel_low",
				"oth2_pitch_comments",
				"look",
				"arm_slot",
				"arm_act",
				"del",
				"deception",
				"ath"
			];
		} else if (evaluation.eval_type.toLowerCase() === "pro_hit") {
			fieldsToKeep = [
				"hitting_ability_comments",
				"hitting_ability_future",
				"hitting_ability_present",
				"ab_quality_present",
				"ab_quality_future",
				"ab_quality_comments",
				"raw_power_comments",
				"raw_power_future",
				"raw_power_present",
				"power_production_comments",
				"power_production_future",
				"power_production_present",
				"run_speed_comments",
				"run_speed_future",
				"run_speed_present",
				"base_running_comments",
				"base_running_future",
				"base_running_present",
				"position_in_ml_present",
				"position_in_ml_future",
				"position_in_ml_comments",
				"arm_strength_comments",
				"arm_strength_future",
				"arm_strength_present",
				"arm_accuracy_comments",
				"arm_accuracy_future",
				"arm_accuracy_present",
				"hands_present",
				"hands_future",
				"hands_comments",
				"range_present",
				"range_future",
				"range_comments",
				"defense_present",
				"defense_future",
				"defense_comments",
				"receiving_present",
				"receiving_future",
				"receiving_comments",
				"blocking_present",
				"blocking_future",
				"blocking_comments",
				"look",
				"arm_slot"
			];
		}
		for (let i = 0; i < fieldsToKeep.length; i++) {
			evaluation[fieldsToKeep[i]] = previousEval[fieldsToKeep[i]];
		}
	} else if (nowYear === reportYear) {
		evaluation = {
			...previousEval,
			...evaluation,
			record_status: "PR",
			eval_report_type: "PRO"
		};
	}

	// Fix pitch comments (no longer needed as legacy fields should not be carried over anymore)
	if (evaluation.eval_type.toLowerCase() === "pro_pitch" && reportYear < 2019) {
		evaluation["cb_comments"] = `${evaluation.cb_comments != null ? evaluation.cb_comments : ""}${
			evaluation.cb_comments != null && evaluation.cb_cmd_comments != null ? ". " : ""
		}${evaluation.cb_cmd_comments != null ? `${evaluation.cb_cmd_comments}` : ""}`;
		evaluation["cb_cmd_comments"] = null;
		evaluation["ch_comments"] = `${evaluation.ch_comments != null ? evaluation.ch_comments : ""}${
			evaluation.ch_comments != null && evaluation.sl_cmd_comments != null ? ". " : ""
		}${evaluation.sl_cmd_comments != null ? `${evaluation.ch_cmd_comments}` : ""}`;
		evaluation["ch_cmd_comments"] = null;
		evaluation["sl_comments"] = `${evaluation.sl_comments != null ? evaluation.sl_comments : ""}${
			evaluation.sl_comments != null && evaluation.sl_cmd_comments != null ? ". " : ""
		}${evaluation.sl_cmd_comments != null ? `${evaluation.sl_cmd_comments}` : ""}`;
		evaluation["sl_cmd_comments"] = null;
		evaluation["oth_pitch_comments"] = `${
			evaluation.oth_pitch_comments != null ? evaluation.oth_pitch_comments : ""
		}${evaluation.oth_pitch_comments != null && evaluation.oth_cmd_comments != null ? ". " : ""}${
			evaluation.oth_cmd_comments != null ? `${evaluation.oth_cmd_comments}` : ""
		}`;
		evaluation["oth_cmd_comments"] = null;
		evaluation["oth2_pitch_comments"] = `${
			evaluation.oth2_pitch_comments != null ? evaluation.oth2_pitch_comments : ""
		}${evaluation.oth2_pitch_comments != null && evaluation.oth2_cmd_comments != null ? ". " : ""}${
			evaluation.oth2_cmd_comments != null ? `${evaluation.oth2_cmd_comments}` : ""
		}`;
		evaluation["oth2_cmd_comments"] = null;
		evaluation["fb_vel_comments"] = `${evaluation.fb_vel_comments != null ? evaluation.fb_vel_comments : ""}${
			evaluation.fb_vel_comments != null && evaluation.fb_cmd_comments != null ? ". " : ""
		}${evaluation.fb_cmd_comments != null ? `${evaluation.fb_cmd_comments}` : ""}${
			evaluation.fb_mov_comments != null && evaluation.fb_mov_comments != null ? ". " : ""
		}${evaluation.fb_mov_comments != null ? `${evaluation.fb_mov_comments}` : ""}`;
		evaluation["fb_cmd_comments"] = null;
	}

	if (evaluation["del"]) evaluation["_del"] = evaluation["del"];

	if (evaluation.ofp != null && evaluation.ofp !== "")
		evaluation.category = getProspectTypePro(evaluation.ofp, evaluation);

	return evaluation;
};

function dateToNum(d) {
	d = d.split("-");
	return Number(d[0] + d[1] + d[2]);
}

const convertGroupNumberToOfp = groupNumber => {
	switch (groupNumber) {
		case "8":
			return 80;
		case "7":
			return 64;
		case "6+":
			return 59;
		case "6":
			return 56;
		case "5+":
			return 54;
		case "5":
			return 52;
		case "4":
			return 49;
		case "3":
			return 46;
		case "2":
			return 42;
		default:
			return 0;
	}
};

export function sortEvals(evals, sort) {
	return evals.sort((a, b) => {
		let valueA = "";
		let valueB = "";
		if (sort.column === "name") {
			valueA = `${a.last_name} ${a.first_name}`;
			valueB = `${b.last_name} ${b.first_name}`;
		} else if (sort.column === "reportType") {
			valueA = `${REPORT_TYPE_MAP[a.eval_type]}${
				a.eval_type.startsWith("AMA") && a.eval_report_type === "FOL"
					? " (Follow)"
					: a.eval_type.startsWith("AMA") && a.eval_report_type === "ID"
					? " (ID)"
					: ""
			}`;
			valueB = `${REPORT_TYPE_MAP[b.eval_type]}${
				b.eval_type.startsWith("AMA") && b.eval_report_type === "FOL"
					? " (Follow)"
					: b.eval_type.startsWith("AMA") && b.eval_report_type === "ID"
					? " (ID)"
					: ""
			}`;
		} else if (sort.column === "position") {
			valueA = `${a.position}`;
			valueB = `${b.position}`;
		} else if (sort.column === "ofp") {
			valueA = a.eval_group ? convertGroupNumberToOfp(a.eval_group) : a.ofp;
			valueB = b.eval_group ? convertGroupNumberToOfp(b.eval_group) : b.ofp;
		} else if (sort.column === "birth_country") {
			valueA = a.birth_country != null ? a.birth_country : sort.asc ? "zzzzz" : "";
			valueB = b.birth_country != null ? b.birth_country : sort.asc ? "zzzzz" : "";
		} else if (sort.column === "category") {
			valueA = `${PROSPECT_TYPE_MAP_PRO[a.category]}`;
			valueB = `${PROSPECT_TYPE_MAP_PRO[b.category]}`;
		} else if (sort.column === "dateSeen") {
			valueA = a.seen_date != null ? dateToNum(a.seen_date.split(" ")[0]) : null;
			valueB = b.seen_date != null ? dateToNum(b.seen_date.split(" ")[0]) : null;
		} else if (sort.column === "publishDate") {
			valueA = a.publish_date != null ? dateToNum(a.publish_date.split(" ")[0]) : null;
			valueB = b.publish_date != null ? dateToNum(b.publish_date.split(" ")[0]) : null;
		} else if (sort.column === "eligible_year") {
			valueA = a.eligible_year != null ? a.eligible_year : sort.asc ? 9999 : 0;
			valueB = b.eligible_year != null ? b.eligible_year : sort.asc ? 9999 : 0;
		} else if (sort.column === "scout") {
			valueA = `${a.scout_last_name} ${a.scout_first_name}`;
			valueB = `${b.scout_last_name} ${b.scout_first_name}`;
		} else if (sort.column === "team") {
			valueA = a.team_display_name ? a.team_display_name : a.team_name;
			valueB = b.team_display_name ? b.team_display_name : b.team_name;
		} else if (sort.column === "acquire") {
			valueA = a.acquire ?? "";
			valueB = b.acquire ?? "";
		}

		if (valueA > valueB) return sort.asc ? 1 : -1;
		else if (valueA < valueB) return sort.asc ? -1 : 1;
		else return 0;
	});
}
