// Player Page New Tabs
export const VIEW_PROJECTIONS = "projections";
export const VIEW_OBSERVED = "observed";
export const VIEW_SCOUTING_REPORTS = "scouting-reports";
export const VIEW_GAME_REPORTS = "game-reports";
export const VIEW_SCHEDULE = "schedule";

// Player Page Old Tabs
export const VIEW_BIO = "bio";
export const VIEW_STATISTICS = "statistics";
export const VIEW_CONTRACT = "contract";
export const VIEW_TRANSACTIONS = "transactions";
export const VIEW_INJURIES = "injuries";
export const VIEW_MANUEL = "plan";
export const VIEW_VIDEO = "video";
export const VIEW_NOTES = "notes";

// Player Page Tab Groups

// Used to determine whether user is on a valid tab when switching view classifications
export const PRO_NEW_TABS = [
	VIEW_PROJECTIONS,
	VIEW_OBSERVED,
	VIEW_STATISTICS,
	VIEW_SCOUTING_REPORTS,
	VIEW_GAME_REPORTS,
	VIEW_CONTRACT,
	VIEW_TRANSACTIONS,
	VIEW_INJURIES,
	VIEW_NOTES,
	VIEW_MANUEL
];
export const AMA_NEW_TABS = [
	VIEW_PROJECTIONS,
	VIEW_OBSERVED,
	VIEW_STATISTICS,
	VIEW_SCOUTING_REPORTS,
	VIEW_SCHEDULE,
	VIEW_BIO,
	VIEW_VIDEO,
	VIEW_NOTES
];
export const INTL_NEW_TABS = [
	VIEW_PROJECTIONS,
	VIEW_OBSERVED,
	VIEW_SCOUTING_REPORTS,
	VIEW_BIO,
	VIEW_INJURIES,
	VIEW_NOTES,
	VIEW_VIDEO
];
